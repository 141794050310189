<template>
  <header class="text-center px-sm-3 px-0">
    <!-- Chapter -->
    <h4 class="mt-2">
      {{ $t('pages.businessGuide.chapter') }} {{ chapter }}
    </h4>
    <!-- /Chapter -->

    <!-- Image -->
    <img class="image mt-3 mb-1" :src="image" alt="">
    <!-- /Image -->

    <!-- Name -->
    <h1 class="title my-4">
      {{ title }}
    </h1>
    <!-- /Name -->

    <!-- Text -->
    <p class="h4">
      {{ subtitle }}
    </p>
    <p class="h4">
      {{ description }}
    </p>
    <p class="text-regular o-50">
      {{ $t('dialogs.concept.hereAreAllStepsIncluded') }}
    </p>
    <!-- Text -->
  </header>
</template>

<script>
export default {
  name: 'IntroHeader',

  props: {
    chapter: {
      type: Number,
      required: true
    },

    image: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 38px !important;
  font-family: outfit-semi-bold, serif;
}

.image {
  width: 105px;
}

.h4 {
  line-height: 1.44;
  margin: 12px;
}
</style>
