<template>
  <div>
    <h3>{{ title }}</h3>
    <p>
      {{ description }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IntroStepInfo',

  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 10px 0;
}

p, ul {
  font-size: 16px;
  line-height: 1.5;
}
</style>
