<template>
  <ib-dialog
    :visible="visible"
    :fullscreen="true"
    @close="close"
  >
    <ib-dialog-container max-width="940px">

      <!-- Header -->
      <intro-header
        :chapter="5"
        :image="require('@/assets/img/illustrations/idea-score-latest1.svg')"
        :title="$t('dialogs.validation.validation')"
        :subtitle="$t('dialogs.validation.howGoodIsYourIdea')"
        :description="$t('dialogs.validation.thisFinalChapterWillGiveYouAnswers')"
      />
      <!-- /Header -->

      <!-- Divider -->
      <intro-divider />
      <!-- /Divider -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper mb-5">

        <!-- Final Step -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill>{{ $t('dialogs.validation.finalStep.finalStep') }}</ib-pill>
              <intro-step-info
                :title="$t('dialogs.validation.finalStep.ideaScore')"
                :description="$t('dialogs.validation.finalStep.takeTheTest')"
              />
            </div>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content">
              <img class="mw-100" src="@/assets/img/icons/tutorials/validation/final-step.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Final Step -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Dialog Bottom -->
        <intro-footer
          :btn-text="$t('dialogs.validation.button')"
          @click="onStart"
        />
        <!-- /Dialog Bottom -->

      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import IntroDivider from './Components/IntroDivider'
import IntroFooter from './Components/IntroFooter'
import IntroHeader from './Components/IntroHeader'
import IntroStepInfo from './Components/IntroStepInfo'

export default {
  name: 'IntroValidation',

  components: {
    IntroStepInfo,
    IntroDivider,
    IntroHeader,
    IntroFooter
  },

  props: {
    visible: Boolean
  },

  methods: {
    close () {
      this.$emit('close')
    },

    onStart () {
      this.$router.push({ name: 'validation-idea-score' })

      this.$gtm.trackEvent({
        event: 'validationCTA'
      })
    }
  }
}
</script>
