<template>
  <div id="story-mode-steps">
    <div class="container-1200">
      <div class="content">

        <!-- Header -->
        <header class="page-header">
          <!-- Dashboard Header -->
          <dashboard-header
            :title="$t('sidebar.businessGuide')"
            :quick-tour-visible="quickTourVisible"
            hide-guide-button
            @toggle-quick-tour="toggleQuickTour"
          />
          <!-- /Dashboard Header -->

          <hr>
        </header>
        <!-- /Header -->

        <!-- Quick tour -->

        <quick-tour-card-wrapper
          :active="quickTourVisible"
          closable
          @close="onCloseQuickTour"
        >
          <business-guide-quick-tour />
        </quick-tour-card-wrapper>

        <!-- /Quick tour -->

        <!-- Wrapper -->
        <div v-if="isPageContentVisible" class="d-flex flex-column-reverse flex-md-column steps-wrapper">
          <el-row :gutter="16" class="step-row mb-md-4 d-flex flex-column flex-md-row">
            <el-col v-for="step in steps" :key="step.name" class="d-flex mb-2 mb-md-0">
              <business-guide-step-card :step-data="step" @open-intro="openIntro" />
            </el-col>

            <!-- Steps -->
            <!--            <el-col v-for="step in steps" :key="step.name" class="d-flex mt-1">-->
            <!--              <step :step-data="step" @open-intro="openIntro" />-->
            <!--            </el-col>-->
            <!-- /Steps -->

          </el-row>
          <el-row class="mb-4 d-flex justify-content-md-end">
            <progress-card :percentage="completedPercentage" />
          </el-row>
        </div>
        <!-- /Wrapper -->

        <!-- Intro Concept -->
        <intro-concept :visible="introName === 'concept'" @close="closeIntro" />
        <!-- /Intro Concept -->

        <!-- Intro Research -->
        <intro-research :visible="introName === 'research'" @close="closeIntro" />
        <!-- /Intro Research -->

        <!-- Intro Set Up -->
        <intro-setup :visible="introName === 'set-up'" @close="closeIntro" />
        <!-- /Intro Set Up -->

        <!-- Intro Projections -->
        <intro-projections :visible="introName === 'projections'" @close="closeIntro" />
        <!-- /Intro Projections -->

        <!-- Intro Validation -->
        <intro-validation :visible="introName === 'validation'" @close="closeIntro" />
        <!-- /Intro Validation -->

      </div>
    </div>

  </div>
</template>

<script>
import BusinessGuideQuickTour from '@/views/Home/Components/QuickTours/BusinessGuideQuickTour'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import IntroConcept from './Components/Intro/IntroConcept'
import IntroProjections from './Components/Intro/IntroProjections'
import IntroResearch from './Components/Intro/IntroResearch'
import IntroSetup from './Components/Intro/IntroSetup'
import IntroValidation from './Components/Intro/IntroValidation'
import MixinGuide from '@/mixins/guide'
import MixinProgressBar from '@/mixins/progressBar'
import MixinStoryModeSteps from '@/mixins/storyModeSteps'
import ProgressCard from '@/components/_v2/ProgressCard'
import QuickTourCardWrapper from '@/views/Home/Components/QuickTours/QuickTourCardWrapper'
// import Step from './Components/Step/Step'

import { mapActions, mapGetters } from 'vuex'
import BusinessGuideStepCard from '@/views/Home/StoryMode/Components/BusinessGuideStepCard/BusinessGuideStepCard.vue'

export default {
  name: 'BusinessGuide',

  components: {
    BusinessGuideStepCard,
    BusinessGuideQuickTour,
    DashboardHeader,
    IntroConcept,
    IntroProjections,
    IntroResearch,
    IntroSetup,
    IntroValidation,
    ProgressCard,
    QuickTourCardWrapper
    // Step
  },

  mixins: [
    MixinStoryModeSteps,
    MixinProgressBar,
    MixinStoryModeSteps,
    MixinGuide
  ],

  data () {
    return {
      introVisible: false,
      introName: '',
      quickTourVisible: false,
      loadingNextButton: false,
      isPageContentVisible: true
    }
  },

  computed: {
    ...mapGetters('idea', [
      'isIdeaSelected',
      'countStoryModeCompletedSteps',
      'countStoryModeTotalSteps'
    ]),

    completedPercentage () {
      return Number(((100 / this.countStoryModeTotalSteps) * this.countStoryModeCompletedSteps).toFixed(1))
    }
  },

  mounted () {
    this.getBusinessGuideCompletedSteps()
  },

  created () {
    this.openQuickTour('businessGuideQuickTour')
  },

  methods: {
    ...mapActions('idea', ['getBusinessGuideCompletedSteps']),

    openIntro (introName) {
      this.introName = introName
    },

    closeIntro () {
      this.introName = ''
    },

    onCloseQuickTour () {
      this.quickTourVisible = false
      this.isPageContentVisible = true
    },

    toggleQuickTour () {
      this.quickTourVisible = !this.quickTourVisible
      this.isPageContentVisible = true

      this.$gtm.trackEvent({
        event: 'playIntro'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .step-row {
    overflow-x: auto;
    padding-bottom: 2px;
     //column-gap: 10px;
    //row-gap: 10px;
  }
</style>
