<template>
  <div class="text-center">
    <p class="h4">
      {{ $t('dialogs.concept.dontWorryItsEasy') }}
    </p>
    <ib-button
      class="button text-uppercase py-3"
      :disabled="disabled"
      @click="$emit('click')"
    >
      {{ btnText }}
    </ib-button>
  </div>
</template>

<script>
export default {
  name: 'IntroFooter',

  props: {
    btnText: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  @include media-breakpoint-up($md) {
    width: 50%;
  }
}
</style>
